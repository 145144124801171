import React from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";

const Cartonfijo = ({ numeros, email, id, mensaje, sala }) => {
  //console.log(email);
  // const query = collection(db, `fijos`);
  // const [docs, loading, error] = useCollectionData(query);

  const agregarcarrito = async (e) => {
    console.log(email)
    const datos = {
      email: email,
      b1: numeros[0][0],
      b2: numeros[0][1],
      b3: numeros[0][2],
      b4: numeros[0][3],
      b5: numeros[0][4],
      i1: numeros[1][0],
      i2: numeros[1][1],
      i3: numeros[1][2],
      i4: numeros[1][3],
      i5: numeros[1][4],
      n1: numeros[2][0],
      n2: numeros[2][1],
      n4: numeros[2][2],
      n5: numeros[2][3],
      g1: numeros[3][0],
      g2: numeros[3][1],
      g3: numeros[3][2],
      g4: numeros[3][3],
      g5: numeros[3][4],
      o1: numeros[4][0],
      o2: numeros[4][1],
      o3: numeros[4][2],
      o4: numeros[4][3],
      o5: numeros[4][4],
    }
    
    fetch(`${process.env.REACT_APP_BASE}/agregar${sala}`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
      })
      .then((response) => response.json())
      .then((result) => {
      if (result.message =="guardado") {
        document.getElementById("boton" + id).innerHTML =
          '<svg class="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/></svg><span>&nbsp;LISTO</span>';
        document.getElementById("boton" + id).className =
          " flex place-content-center mt-2 w-full text-black bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ";
        document.getElementById("boton" + id).disabled = true;
        mensaje("Agregado al Carrito fijo", "save");
      } else {
        mensaje(result.message, "warning");
      }
      })
      .catch((error) => {
      console.log(error);
      });
      //fin de envio

    // if (docs) {
    //   //console.log(docs.length);
    //   if (docs.length == 200) {
    //     mensaje("Ya tienes 200 Cartones Fijos", "warning");
    //   } else {
    //     //console.log(document.getElementById("boton" + id).innerHTML);
    //     document.getElementById("boton" + id).innerHTML =
    //       '<svg class="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/></svg><span>&nbsp;LISTO</span>';
    //     document.getElementById("boton" + id).className =
    //       " flex place-content-center mt-2 w-full text-black bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ";
    //     document.getElementById("boton" + id).disabled = true;
    //     const path = `fijos`;
    //     const cartonesCollection = collection(db, path);
    //     const docId = "C" + (docs.length + 1); // or any other desired ID
    //     const docRef = doc(cartonesCollection, docId);

    //     setDoc(docRef, {
    //       estatus: "pendiente",
    //       numerocarton: docs.length + 1,
    //       b1: numeros[0][0],
    //       b2: numeros[0][1],
    //       b3: numeros[0][2],
    //       b4: numeros[0][3],
    //       b5: numeros[0][4],
    //       i1: numeros[1][0],
    //       i2: numeros[1][1],
    //       i3: numeros[1][2],
    //       i4: numeros[1][3],
    //       i5: numeros[1][4],
    //       n1: numeros[2][0],
    //       n2: numeros[2][1],
    //       n4: numeros[2][2],
    //       n5: numeros[2][3],
    //       g1: numeros[3][0],
    //       g2: numeros[3][1],
    //       g3: numeros[3][2],
    //       g4: numeros[3][3],
    //       g5: numeros[3][4],
    //       o1: numeros[4][0],
    //       o2: numeros[4][1],
    //       o3: numeros[4][2],
    //       o4: numeros[4][3],
    //       o5: numeros[4][4],
    //     });
    //     mensaje("Agregado al Carrito fijo", "save");
    //   }
    // }
  };

  const mostrarcarton = () => {
    //console.log(numeros);
  };
  return (
    <div class="tablero">
      <table id="bingo-table">
        <tr>
          <th>
            <span class="encabezado1">B</span>
          </th>
          <th>
            <span class="encabezado1">I</span>
          </th>
          <th>
            <span class="encabezado1">N</span>
          </th>
          <th>
            <span class="encabezado1">G</span>
          </th>
          <th>
            <span class="encabezado1">O</span>
          </th>
        </tr>
        <tr>
          <td class="">{numeros[0][0]}</td>
          <td class="">{numeros[1][0]}</td>
          <td class="">{numeros[2][0]}</td>
          <td class="">{numeros[3][0]}</td>
          <td class="">{numeros[4][0]}</td>
        </tr>
        <tr>
          <td class="">{numeros[0][1]}</td>
          <td class="">{numeros[1][1]}</td>
          <td class="">{numeros[2][1]}</td>
          <td class="">{numeros[3][1]}</td>
          <td class="">{numeros[4][1]}</td>
        </tr>
        <tr>
          <td class="">{numeros[0][2]}</td>
          <td class="">{numeros[1][2]}</td>
          <td class="marcador3 ">
            <svg
              class="w-6 h-6 text-yellow-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
            </svg>
          </td>
          <td class="">{numeros[3][2]}</td>
          <td class="">{numeros[4][2]}</td>
        </tr>
        <tr>
          <td class="">{numeros[0][3]}</td>
          <td class="">{numeros[1][3]}</td>
          <td class="">{numeros[2][2]}</td>
          <td class="">{numeros[3][3]}</td>
          <td class="">{numeros[4][3]}</td>
        </tr>
        <tr>
          <td class="">{numeros[0][4]}</td>
          <td class="">{numeros[1][4]}</td>
          <td class="">{numeros[2][3]}</td>
          <td class="">{numeros[3][4]}</td>
          <td class="">{numeros[4][4]}</td>
        </tr>
      </table>
      <span id={`boton${id}`}>
        <button
          type="button"
          onClick={() => {
            agregarcarrito();
          }}
          class=" flex place-content-center mt-2 w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
        >
          <svg
            class="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M5 3a1 1 0 0 0 0 2h.687L7.82 15.24A3 3 0 1 0 11.83 17h2.34A3 3 0 1 0 17 15H9.813l-.208-1h8.145a1 1 0 0 0 .979-.796l1.25-6A1 1 0 0 0 19 6h-2.268A2 2 0 0 1 15 9a2 2 0 1 1-4 0 2 2 0 0 1-1.732-3h-1.33L7.48 3.796A1 1 0 0 0 6.5 3H5Z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M14 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V8h1a1 1 0 1 0 0-2h-1V5Z"
              clip-rule="evenodd"
            />
          </svg>
          <span>&nbsp;Agregar</span>
        </button>
      </span>
    </div>
  );
};

export default Cartonfijo;
