import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import maracaibo from "../img/recarga.png";
import figura from "../img/figuras/cuadrado.png";
import trofeow from "../img/trofeow.png";
import fotoreloj from "../img/retiros.gif";
import banco from "../img/bdv.png";
import capture from "../img/capture.jpg"
import useSound from "use-sound";
import { Button, Drawer, Radio, Space, Popover } from "antd";

import { Link, UNSAFE_DataRouterStateContext } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Menudeopciones from "./menudeopciones";



import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
} from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, getDocs, deleteDoc } from "@firebase/firestore";
import { validatePassword } from "firebase/auth";



const text = <span>Configuracion de Cuenta</span>;

const content = (
  <Menudeopciones />
);

const content2 = (
    <div className="flex flex-row">
      <img src={capture} className=" border-2 border-gray-500 rounded-lg" width="180px" height="140px" alt="" />
      </div>
  );



const Retiros = () => {
  const { width, height } = "100%";

  const [activar, SetActivar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [cards, setCards] = useState('recarga');
  const [banco, setBanco] = useState('')
  const [cedula, setCedula] = useState('')
  const [telefono, setTelefono] = useState('')

  const [historial, setHistorial] = useState([])

  
  const menurecarga = () => {
    setCards("recarga")
  }

  const menuhistorial = () => {
  setCards("historial")
  obtenerhistorial()
}

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  function formatearFecha(fechaISO) {
    // Crear un objeto Date a partir de la fecha ISO
    const fecha = new Date(fechaISO);
    
    // Obtener el día y el mes
    const dia = fecha.getUTCDate();
    const opcionesMes = { month: 'short' }; // 'short' para obtener el mes abreviado
    const mes = new Intl.DateTimeFormat('es-ES', opcionesMes).format(fecha);
    
    // Obtener la hora en formato 24 horas
    const horas = String(fecha.getUTCHours()).padStart(2, '0');
    const minutos = String(fecha.getUTCMinutes()).padStart(2, '0');
    
    // Formatear la salida
    return `${dia} ${mes} ${horas}:${minutos}`;
}

  const obtenerhistorial = async () => {

  
    
    const emailu = email

    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerhistorialretiros/`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ emailu }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message == "no hay"){
            setHistorial([])
          }else
          {
            setHistorial(data.historial)
          }
        //console.log(data);
        }else{
          //console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //console.log("SE EJECUTO")
    // if(activaintervalo){
    //   setActivaintervalo(false)
    // }else{
    //   setActivaintervalo(true)
    // }
};

const obtenerpagomovil = async () => {

  
    
    const emailu = email
    console.log(emailu)
    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/pagomovil`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ emailu }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message == "guardado"){
            obtenerpagomovil()
            
          }else
          {
            setBanco(data.cuentas[0].banco)
            setCedula(data.cuentas[0].cedula)
            setTelefono(data.cuentas[0].telefono)
            console.log(data.cuentas)
          }
        //console.log(data);
        }else{
          //console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //console.log("SE EJECUTO")
    // if(activaintervalo){
    //   setActivaintervalo(false)
    // }else{
    //   setActivaintervalo(true)
    // }
};

const configurarpagomovil = async () => {

  
    
  const banco = document.getElementById("bancomovil").value
  const cedula = document.getElementById("cedulamovil").value
  const telefono = document.getElementById("telefonomovil").value
  const emailu = email

  console.log(banco)
  console.log(cedula)
  console.log(telefono)
  console.log(emailu)
  

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/actualizamovil/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({emailu, banco, cedula, telefono }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message == "modificado"){
            obtenerpagomovil()
      setShowModal2(false)
      
        }
      //console.log(data);
      }else{
        //console.log("ERROR");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }

};

  const mensaje = (value, tipo) => {

      msjsave(value, tipo);

  };

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };



  const [iniciando, setIniciando] = useState(true);
  const [iniciando2, setIniciando2] = useState(false);
  const [salir, setSalir] = useState(false);
  const [emaillocal, setEmaillocal] = useState("user@example.com");

  
  const [emailf, setEmailf] = useState("");


  const [users, setUsers] = useState([]);
  
  const [saldo, setSaldo] = useState(0)
  const [regalos, setRegalos] = useState(0)




  const registraruser = async () => {
    
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
        referido: "enmarycarrasco@gmail.com",
        usuario: ""
      };

      registrarusuario(datos)
    }
  };


  const registrarusuario = async (datos) => {

   
    //enviar datos
    fetch(`${process.env.REACT_APP_BASE}/nuevouser`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos),
    })
    .then((response) => response.json())
    .then((result) => {
    if (result) {
    //console.log(result);
    } else {
    //console.log('ERROR DE CONEXION');
    }
    })
    .catch((error) => {
    //console.log(error);
    });
    //fin de envio
 

  }

  const chequearexistencia = async () => {

    const datos = {
        email: email,
    }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/chequearexistencia`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result) {
            if(result.message == "Si existe"){
                //console.log(result.message)
                setSaldo(result.saldo)
                setRegalos(result.regalos)
            }
            else{
                //console.log("no existe")
               registraruser()
            }
        } else {
        //console.log('ERROR DE CONEXION');
        }
        })
        .catch((error) => {
        //console.log(error);
        });
        //fin de envio

  }

  const chequearexistencia2 = () => {
    var existe = false;

    for (let i = 0; i < users.length; i++) {
      if (users[i].email == emailf) {
        existe = true;
      }
    }
    //console.log(existe);
    if (existe == true) {
    
    }
  };



    const registrarretiro = async (e) => {

      var monto = document.getElementById("montopago").value
      

 
      const datos = {
        email: email,
        monto: monto,
      }
      
      fetch(`${process.env.REACT_APP_BASE}/registrarretiro`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result.message =="modificado") {
          msjsave("Retiro Exitoso", "save")
          chequearexistencia()
          obtenerhistorial()

          setCards("historial")
        } else {
          msjsave("El Monto a Retirar es Superior a su saldo", "error")
        }
        })
        .catch((error) => {
        console.log(error);
        });
       
    };
    




  const obtenernombre = (correo, tipo) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email == correo) {
        let nombre = users[i].nombre;
        var PrimerN = "";
        for (let a = 0; a < nombre.length; a++) {
          if (nombre[a] === " ") {
            a = nombre.length;
          } else {
            PrimerN += nombre[a];
          }
        }
        if (tipo == "nombre") {
          return PrimerN;
        }
        if (tipo == "foto") {
          return users[i].photo;
        }
      }
    }
  };





useEffect(() => {
  console.log(historial)
}, [historial]);



  useEffect(() => {
    if (email === undefined) {
  
   
     
      chequearexistencia();
      //setShowModal2(true);
    } else {

      obtenerhistorial()
      obtenerpagomovil()
     
      chequearexistencia();
      setShowModal2(false);
    }

  }, [email]);


  return (
    <>
      <div className="container2 w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                
                <div>
                <div className="text-white text-[14px] font-medium text-left ml-6 mt-[-5px]">
                      {displayName && <> {primerNombre} </>}
                    </div>
                    <div className="bg-blue-950 w-[175px] h-10 rounded-lg grid grid-cols-3 gap-3" >

                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-10 h-10 ml-[-5px]  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="bg-blue-950 w-[60px] h-10 ml-[-15px] pt-2">
                    <span className="text-[12px] relative"><svg class=" absolute w-[24px] ml-[-4px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"/>
</svg>

 <span className="absolute mt-[0px] w-16 ml-[22px] bg-gray-600 rounded-xl p-1 pl-2 pr-2 text-[12px] font-medium shadow-md">{parseInt(saldo)} Bs</span></span>
                    
                  </div>
                  <div className="bg-blue-950 rounded-r-lg flex flex-row place-content-center mt-[6px]">
                  <Popover placement="bottomRight" title="Configuración de Cuenta" content={content}>
            <svg class="w-[32px] h-[32px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M17 10v1.126c.367.095.714.24 1.032.428l.796-.797 1.415 1.415-.797.796c.188.318.333.665.428 1.032H21v2h-1.126c-.095.367-.24.714-.428 1.032l.797.796-1.415 1.415-.796-.797a3.979 3.979 0 0 1-1.032.428V20h-2v-1.126a3.977 3.977 0 0 1-1.032-.428l-.796.797-1.415-1.415.797-.796A3.975 3.975 0 0 1 12.126 16H11v-2h1.126c.095-.367.24-.714.428-1.032l-.797-.796 1.415-1.415.796.797A3.977 3.977 0 0 1 15 11.126V10h2Zm.406 3.578.016.016c.354.358.574.85.578 1.392v.028a2 2 0 0 1-3.409 1.406l-.01-.012a2 2 0 0 1 2.826-2.83ZM5 8a4 4 0 1 1 7.938.703 7.029 7.029 0 0 0-3.235 3.235A4 4 0 0 1 5 8Zm4.29 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h6.101A6.979 6.979 0 0 1 9 15c0-.695.101-1.366.29-2Z" clip-rule="evenodd"/>
</svg>
          </Popover>
                  
                  

                  </div>
                  </div>
                </div>
                  
                  

                  


                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>
      {email != undefined || emailf != "" ? (
        <>
          <div class=" flex">
            <div className="relative m-2 mt-10 bg-yellow-500 text-lg text-blue-800 font-mono pl-24 font-bold h-[50px] w-[96%] rounded-lg border-2">
              <span className=" absolute m-0 mt-6 text-sm text-cyan-700">
                Saldo Actual
              </span>
              <span className="pl-2 pr-2 absolute m-0 mt-6 ml-[100px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg">
                {saldo} Bs
              </span>
              <svg class="absolute w-[32px] h-[32px] text-red-600 ml-[200px] mt-3"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"/>
</svg>

              <span className="pl-2 pr-2 absolute m-0 mt-[7px] ml-[224px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-full">
                {regalos}
              </span>
              RETIRAR FONDOS
            </div>
            <img
              src={maracaibo}
              height="50px"
              className="m-2 mt-10 rounded-l-lg border-2 absolute h-[50px]"
            />
            <div className="m-2 items-end mt-10 rounded-l-lg border-2 absolute h-[50px]"></div>
            {/* <img
              src={figuraurl}
              className="m-2 items-end ml-[300px] mt-5 rounded-lg border-2 absolute h-[50px] bg-slate-300"
            /> */}
            
          </div>
          {iniciando == true ? (
            <>
            <table width="100%" style={{border: "0"}}>
              <tr width="60%">
                <td>
                <div className="w-50% ml-4">
                  <div className="flex flex-col place-content-end">
                    <div className="text-[14px] text-red-700 font-sans font-bold">Realiza Retiros de Saldo</div>
                <div class="inline-flex rounded-md mt-2 shadow-sm" role="group">
  <button onClick={() => {
  menurecarga();
  }} type="button" class="inline-flex bg-[#09096c] items-center px-4 py-2 text-[12px] font-medium text-gray-200 border border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white">
  <svg class="w-[24px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
</svg> &nbsp; Retirar
  </button>
 
  <button onClick={() => {
  menuhistorial();
  }} type="button" class="inline-flex bg-[#09096c] items-center px-4 py-2 text-[12px] font-medium text-gray-200 border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white">
  <svg class="w-[24px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M9 15a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm3.845-1.855a2.4 2.4 0 0 1 1.2-1.226 1 1 0 0 1 1.992-.026c.426.15.809.408 1.111.749a1 1 0 1 1-1.496 1.327.682.682 0 0 0-.36-.213.997.997 0 0 1-.113-.032.4.4 0 0 0-.394.074.93.93 0 0 0 .455.254 2.914 2.914 0 0 1 1.504.9c.373.433.669 1.092.464 1.823a.996.996 0 0 1-.046.129c-.226.519-.627.94-1.132 1.192a1 1 0 0 1-1.956.093 2.68 2.68 0 0 1-1.227-.798 1 1 0 1 1 1.506-1.315.682.682 0 0 0 .363.216c.038.009.075.02.111.032a.4.4 0 0 0 .395-.074.93.93 0 0 0-.455-.254 2.91 2.91 0 0 1-1.503-.9c-.375-.433-.666-1.089-.466-1.817a.994.994 0 0 1 .047-.134Zm1.884.573.003.008c-.003-.005-.003-.008-.003-.008Zm.55 2.613s-.002-.002-.003-.007a.032.032 0 0 1 .003.007ZM4 14a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm3-2a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm6.5-8a1 1 0 0 1 1-1H18a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-.796l-2.341 2.049a1 1 0 0 1-1.24.06l-2.894-2.066L6.614 9.29a1 1 0 1 1-1.228-1.578l4.5-3.5a1 1 0 0 1 1.195-.025l2.856 2.04L15.34 5h-.84a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
</svg>


 &nbsp;Historial
  </button>
</div></div>
                </div>
                </td>
                <td width="40%">
                <div className="h-[85px] rounded-xl border-2 border-blue-900 m-4 flex flex-col place-content-center justify-center items-center bg-[#22c4f9]">
                <img src={fotoreloj} width="85px" height="80px"/>
               
                 
               
              </div>
                </td>
              </tr>
            </table>
              
              {cards == 'recarga' ? (<div className=" flex flex-col ml-4 mr-4 place-content-center  bg-gray-200 border-2 rounded-lg p-0 text-[14px]">
                <div className="bg-blue-400 rounded-t-lg m-0 h-[110px] flex flex-row place-content-center">
                <div className="h-10 mt-8">
                <button onClick={() => {
                setShowModal2(true);
                }} type="button" class="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 me-2 mb-2">
<svg class="w-[28px] h-[28px] text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd"/>
</svg>

Configurar
</button></div>
                    <div className="flex flex-col ml-4 mt-2">
                        <span className=" font-sans font-bold text-red-950 text-xl">Mi Pago Movil</span> 
                        <span className="font-semibold">{banco}</span> 
                        <span className="font-semibold">{cedula}</span> 
                        <span className="font-semibold">{telefono}</span> 
                        
                    </div>
                </div>
                <div className="flex flex-col place-content-start p-4">

                    



<label for="montopago" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Monto 

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z" clip-rule="evenodd"/>
  <path fill-rule="evenodd" d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" clip-rule="evenodd"/>
  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/>
</svg>


  </div>
  <input type="number" id="montopago" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>

<div width="200px">
<button
          type="button"
          onClick={() => {
            registrarretiro();
          }}
          class=" flex place-content-center mt-2 w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
        >
          <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path fill="currentColor" d="M4 19v2c0 .5523.44772 1 1 1h14c.5523 0 1-.4477 1-1v-2H4Z"/>
  <path fill="currentColor" fill-rule="evenodd" d="M9 3c0-.55228.44772-1 1-1h8c.5523 0 1 .44772 1 1v3c0 .55228-.4477 1-1 1h-2v1h2c.5096 0 .9376.38314.9939.88957L19.8951 17H4.10498l.90116-8.11043C5.06241 8.38314 5.49047 8 6.00002 8H12V7h-2c-.55228 0-1-.44772-1-1V3Zm1.01 8H8.00002v2.01H10.01V11Zm.99 0h2.01v2.01H11V11Zm5.01 0H14v2.01h2.01V11Zm-8.00998 3H10.01v2.01H8.00002V14ZM13.01 14H11v2.01h2.01V14Zm.99 0h2.01v2.01H14V14ZM11 4h6v1h-6V4Z" clip-rule="evenodd"/>
</svg>

          <span>&nbsp;RETIRAR DINERO</span>
        </button>
        </div>
                </div>
                
              </div>) : (<div className=" flex flex-col ml-4 mr-4 place-content-center  bg-gray-200 border-2 rounded-lg p-0 text-[14px]">
                <div className="bg-gray-600 rounded-t-lg m-0 h-[60px] flex flex-row place-content-center items-center justify-center"><svg class="w-[24px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M9 15a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm3.845-1.855a2.4 2.4 0 0 1 1.2-1.226 1 1 0 0 1 1.992-.026c.426.15.809.408 1.111.749a1 1 0 1 1-1.496 1.327.682.682 0 0 0-.36-.213.997.997 0 0 1-.113-.032.4.4 0 0 0-.394.074.93.93 0 0 0 .455.254 2.914 2.914 0 0 1 1.504.9c.373.433.669 1.092.464 1.823a.996.996 0 0 1-.046.129c-.226.519-.627.94-1.132 1.192a1 1 0 0 1-1.956.093 2.68 2.68 0 0 1-1.227-.798 1 1 0 1 1 1.506-1.315.682.682 0 0 0 .363.216c.038.009.075.02.111.032a.4.4 0 0 0 .395-.074.93.93 0 0 0-.455-.254 2.91 2.91 0 0 1-1.503-.9c-.375-.433-.666-1.089-.466-1.817a.994.994 0 0 1 .047-.134Zm1.884.573.003.008c-.003-.005-.003-.008-.003-.008Zm.55 2.613s-.002-.002-.003-.007a.032.032 0 0 1 .003.007ZM4 14a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm3-2a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm6.5-8a1 1 0 0 1 1-1H18a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-.796l-2.341 2.049a1 1 0 0 1-1.24.06l-2.894-2.066L6.614 9.29a1 1 0 1 1-1.228-1.578l4.5-3.5a1 1 0 0 1 1.195-.025l2.856 2.04L15.34 5h-.84a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
</svg>
 <span className="text-white">&nbsp;Historial de Retiros</span> &nbsp;</div>
                <div className="flex flex-row place-content-start ">

      
                 


    <table class="w-full text-sm text-left rtl:text-right text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-300">
            <tr>
                <th scope="col" class="px-4 py-3">
                    Fecha
                </th>
                <th scope="col" class="px-4 py-3">
                    Retiro de
                </th>
                
                
                <th scope="col" class="px-4 py-3">
                    <span class="sr-only"></span>
                </th>
            </tr>
        </thead>
        <tbody>
       {historial.map((val, key) => {
       return (<>
       {val.estatus == 'confirmado' ? (<tr class="bg-white border-b   hover:bg-gray-50 ">
                <th scope="row" class="px-4 py-4 font-medium text-gray-600 whitespace-nowrap ">
                    {formatearFecha(val.fecha)}
                                    </th>
                <td class="px-4 py-4">
                    {val.monto} Bs
                </td>
           
                
                <td class="px-4 py-4 text-right flex flex-row">
                <svg class="w-6 h-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

<span className="text-green-500 font-bold mt-1">Pagado</span>
                </td>
            </tr>) : (<></>)}

          {val.estatus == 'procesando' ? (<tr class="bg-white border-b   hover:bg-gray-50 ">
                <th scope="row" class="px-4 py-4 font-medium text-gray-600 whitespace-nowrap ">
                    {formatearFecha(val.fecha)}
                </th>
                <td class="px-4 py-4">
                    {val.monto} Bs
                </td>
           
                
                <td class="px-4 py-4 text-right flex flex-row">
                <Popover placement="topLeft" title="Retiro de Fondos" content={<div><strong><span className="text-blue-700"></span></strong><p className="italic">Estamos procesando tu solicitud.</p></div>}><svg class="w-6 h-6 text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd"/>
</svg></Popover>
<span className="text-yellow-500 font-bold mt-1">Procesando</span>
                </td>
            </tr>) : (<></>)}

            {val.estatus == 'cancelado' ? (<tr class="bg-white border-b   hover:bg-gray-50 ">
                <th scope="row" class="px-4 py-4 font-medium text-gray-600 whitespace-nowrap ">
                    {formatearFecha(val.fecha)}
                </th>
                <td class="px-4 py-4">
                    {val.monto} Bs
                </td>
           
                
                <td class="px-4 py-4 text-right flex flex-row">
                <svg class="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z" clip-rule="evenodd"/>
</svg>

<span className="text-red-500 font-bold mt-1">Cancelado</span>
                </td>
            </tr>) : (<></>)}

       </>);

              

       })}
           
            
        </tbody>
    </table>


           
                </div>
                
              </div>)}
              
      
            </>
          ) : (
            <></>
          )}
          <br />
        </>
      ) : (
        <></>
      )}

      <ToastContainer theme="dark" />
      {salir ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Salir</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Deseas Cerrar Sesi&oacute;n?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLogout();
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
       {/*MODAL DE CARTON Y COMPRAR Y REGALO*/}
      {showModal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-yellow-300">
                {/*body*/}
                <div className="flex flex-row place-content-between">
                <h3 className=" text-lg ml-4 mt-4 text-gray-500 font-bold">
                          Mi Pago Movil
                        </h3>
                
                      <svg onClick={() => {
                        setShowModal2(false);
                      }} class="w-[32px] h-[32px] text-gray-800 mt-2 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
</svg>


                   </div>
                <div className="relative p-2 flex-auto">
                  <div className=" text-center">
                    <div>
                      <div className=" text-center">
                       
                        
                      </div>
                      
            
                      <div className="flex flex-col m-2">
 
                      <label for="bancomovil" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Banco

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M10.915 2.345a2 2 0 0 1 2.17 0l7 4.52A2 2 0 0 1 21 8.544V9.5a1.5 1.5 0 0 1-1.5 1.5H19v6h1a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h1v-6h-.5A1.5 1.5 0 0 1 3 9.5v-.955a2 2 0 0 1 .915-1.68l7-4.52ZM17 17v-6h-2v6h2Zm-6-6h2v6h-2v-6Zm-2 6v-6H7v6h2Z" clip-rule="evenodd"/>
  <path d="M2 21a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z"/>
</svg>



  </div>
  <input type="text" id="bancomovil"  defaultValue={banco} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>


<label for="cedulamovil" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Cedula o Documento

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>



  </div>
  <input type="text" id="cedulamovil" defaultValue={cedula} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>

<label for="telefonomovil" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Telefono

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd"/>
</svg>



  </div>
  <input type="text" id="telefonomovil"  defaultValue={telefono} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>


                            <div className="flex flex-row place-content-end mr-[-10px]">
                                <button
                                  onClick={() => {
                                  configurarpagomovil();
                                  }}
                                  id="configuramovil"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

 <span>Guardar</span>
                                </button>
                              
                              </div>
                      </div>
                    </div>

                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Retiros;
