import React, { useState, useEffect } from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";
import trofeow from "../img/trofeow.png";

const Cartonfinallibre = ({
  numeros,
  nombre,
  photo,
  id,
  idcarton,
  mensaje,
  sacada,
  figuras,
  bolitas,
}) => {
  let nomb = nombre;
  var primerNombre = "";

  for (let i = 0; i < nomb.length; i++) {
    if (nomb[i] === " ") {
      i = nomb.length;
    } else {
      primerNombre += nomb[i];
    }
  }

  const marcarresultados = () => {
    for (let i = 0; i < sacada.length; i++) {
      revisarcarton(sacada[i]);
    }
  };

  const marcarblanco = () => {
    for (let i = 0; i < bolitas.length; i++) {
      revisarcarton1(bolitas[i]);
    }
  };

  const revisarcarton = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 16) {
      chequearb(bolitanueva);
    }
    if (bolitanueva > 15 && bolitanueva < 31) {
      chequeari(bolitanueva);
    }
    if (bolitanueva > 30 && bolitanueva < 46) {
      chequearn(bolitanueva);
    }
    if (bolitanueva > 45 && bolitanueva < 61) {
      chequearg(bolitanueva);
    }
    if (bolitanueva > 60 && bolitanueva < 76) {
      chequearo(bolitanueva);
    }
  };

  const revisarcarton1 = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 16) {
      chequearb1(bolitanueva);
    }
    if (bolitanueva > 15 && bolitanueva < 31) {
      chequeari1(bolitanueva);
    }
    if (bolitanueva > 30 && bolitanueva < 46) {
      chequearn1(bolitanueva);
    }
    if (bolitanueva > 45 && bolitanueva < 61) {
      chequearg1(bolitanueva);
    }
    if (bolitanueva > 60 && bolitanueva < 76) {
      chequearo1(bolitanueva);
    }
  };

  const chequearb = (bolita) => {
    if (numeros.b1 == bolita) {
      document.getElementById(`carto${id}b1`).className = cambiacolor("b1");
    }
    if (numeros.b2 == bolita) {
      document.getElementById(`carto${id}b2`).className = cambiacolor("b2");
    }
    if (numeros.b3 == bolita) {
      document.getElementById(`carto${id}b3`).className = cambiacolor("b3");
    }
    if (numeros.b4 == bolita) {
      document.getElementById(`carto${id}b4`).className = cambiacolor("b4");
    }
    if (numeros.b5 == bolita) {
      document.getElementById(`carto${id}b5`).className = cambiacolor("b5");
    }
  };

  const chequearb1 = (bolita) => {
    if (numeros.b1 == bolita) {
      document.getElementById(`carto${id}b1`).className = "";
    }
    if (numeros.b2 == bolita) {
      document.getElementById(`carto${id}b2`).className = "";
    }
    if (numeros.b3 == bolita) {
      document.getElementById(`carto${id}b3`).className = "";
    }
    if (numeros.b4 == bolita) {
      document.getElementById(`carto${id}b4`).className = "";
    }
    if (numeros.b5 == bolita) {
      document.getElementById(`carto${id}b5`).className = "";
    }
  };

  const chequeari = (bolita) => {
    if (numeros.i1 == bolita) {
      document.getElementById(`carto${id}i1`).className = cambiacolor("i1");
    }
    if (numeros.i2 == bolita) {
      document.getElementById(`carto${id}i2`).className = cambiacolor("i2");
    }
    if (numeros.i3 == bolita) {
      document.getElementById(`carto${id}i3`).className = cambiacolor("i3");
    }
    if (numeros.i4 == bolita) {
      document.getElementById(`carto${id}i4`).className = cambiacolor("i4");
    }
    if (numeros.i5 == bolita) {
      document.getElementById(`carto${id}i5`).className = cambiacolor("i5");
    }
  };

  const chequeari1 = (bolita) => {
    if (numeros.i1 == bolita) {
      document.getElementById(`carto${id}i1`).className = "";
    }
    if (numeros.i2 == bolita) {
      document.getElementById(`carto${id}i2`).className = "";
    }
    if (numeros.i3 == bolita) {
      document.getElementById(`carto${id}i3`).className = "";
    }
    if (numeros.i4 == bolita) {
      document.getElementById(`carto${id}i4`).className = "";
    }
    if (numeros.i5 == bolita) {
      document.getElementById(`carto${id}i5`).className = "";
    }
  };

  const chequearn = (bolita) => {
    if (numeros.n1 == bolita) {
      document.getElementById(`carto${id}n1`).className = cambiacolor("n1");
    }
    if (numeros.n2 == bolita) {
      document.getElementById(`carto${id}n2`).className = cambiacolor("n2");
    }

    if (numeros.n4 == bolita) {
      document.getElementById(`carto${id}n4`).className = cambiacolor("n4");
    }
    if (numeros.n5 == bolita) {
      document.getElementById(`carto${id}n5`).className = cambiacolor("n5");
    }
  };

  const chequearn1 = (bolita) => {
    if (numeros.n1 == bolita) {
      document.getElementById(`carto${id}n1`).className = "";
    }
    if (numeros.n2 == bolita) {
      document.getElementById(`carto${id}n2`).className = "";
    }

    if (numeros.n4 == bolita) {
      document.getElementById(`carto${id}n4`).className = "";
    }
    if (numeros.n5 == bolita) {
      document.getElementById(`carto${id}n5`).className = "";
    }
  };

  const chequearg = (bolita) => {
    if (numeros.g1 == bolita) {
      document.getElementById(`carto${id}g1`).className = cambiacolor("g1");
    }
    if (numeros.g2 == bolita) {
      document.getElementById(`carto${id}g2`).className = cambiacolor("g2");
    }
    if (numeros.g3 == bolita) {
      document.getElementById(`carto${id}g3`).className = cambiacolor("g3");
    }
    if (numeros.g4 == bolita) {
      document.getElementById(`carto${id}g4`).className = cambiacolor("g4");
    }
    if (numeros.g5 == bolita) {
      document.getElementById(`carto${id}g5`).className = cambiacolor("g5");
    }
  };

  const chequearg1 = (bolita) => {
    if (numeros.g1 == bolita) {
      document.getElementById(`carto${id}g1`).className = "";
    }
    if (numeros.g2 == bolita) {
      document.getElementById(`carto${id}g2`).className = "";
    }
    if (numeros.g3 == bolita) {
      document.getElementById(`carto${id}g3`).className = "";
    }
    if (numeros.g4 == bolita) {
      document.getElementById(`carto${id}g4`).className = "";
    }
    if (numeros.g5 == bolita) {
      document.getElementById(`carto${id}g5`).className = "";
    }
  };

  const chequearo = (bolita) => {
    if (numeros.o1 == bolita) {
      document.getElementById(`carto${id}o1`).className = cambiacolor("o1");
    }
    if (numeros.o2 == bolita) {
      document.getElementById(`carto${id}o2`).className = cambiacolor("o2");
    }
    if (numeros.o3 == bolita) {
      document.getElementById(`carto${id}o3`).className = cambiacolor("o3");
    }
    if (numeros.o4 == bolita) {
      document.getElementById(`carto${id}o4`).className = cambiacolor("o4");
    }
    if (numeros.o5 == bolita) {
      document.getElementById(`carto${id}o5`).className = cambiacolor("o5");
    }
  };

  const chequearo1 = (bolita) => {
    if (numeros.o1 == bolita) {
      document.getElementById(`carto${id}o1`).className = "";
    }
    if (numeros.o2 == bolita) {
      document.getElementById(`carto${id}o2`).className = "";
    }
    if (numeros.o3 == bolita) {
      document.getElementById(`carto${id}o3`).className = "";
    }
    if (numeros.o4 == bolita) {
      document.getElementById(`carto${id}o4`).className = "";
    }
    if (numeros.o5 == bolita) {
      document.getElementById(`carto${id}o5`).className = "";
    }
  };

  const cambiacolor = (casilla) => {

    if (idcarton.includes(id) == true) {
        var nfigura = concualfigura();
        if (nfigura === "cruzchica"){
            for (let i = 0; i < figuras.cruzchica.length; i++) {
                if (figuras.cruzchica[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "cruzgrande"){
            for (let i = 0; i < figuras.cruzgrande.length; i++) {
                if (figuras.cruzgrande[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "cuatroesquinas"){
            for (let i = 0; i < figuras.cuatroesquinas.length; i++) {
                if (figuras.cuatroesquinas[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "diagonal1"){
            for (let i = 0; i < figuras.diagonal1.length; i++) {
                if (figuras.diagonal1[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "diagonal2"){
            for (let i = 0; i < figuras.diagonal2.length; i++) {
                if (figuras.diagonal2[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "horizontal1"){
            for (let i = 0; i < figuras.horizontal1.length; i++) {
                if (figuras.horizontal1[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "horizontal2"){
            for (let i = 0; i < figuras.horizontal2.length; i++) {
                if (figuras.horizontal2[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "horizontal3"){
            for (let i = 0; i < figuras.horizontal3.length; i++) {
                if (figuras.horizontal3[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "horizontal4"){
            for (let i = 0; i < figuras.horizontal4.length; i++) {
                if (figuras.horizontal4[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "horizontal5"){
            for (let i = 0; i < figuras.horizontal5.length; i++) {
                if (figuras.horizontal5[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "verticalb"){
            for (let i = 0; i < figuras.verticalb.length; i++) {
                if (figuras.verticalb[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "verticali"){
            for (let i = 0; i < figuras.verticali.length; i++) {
                if (figuras.verticali[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "verticaln"){
          for (let i = 0; i < figuras.verticaln.length; i++) {
              if (figuras.verticaln[i] == casilla) {
                return "marcador3";
              }
            }
      }
        if (nfigura === "verticalg"){
            for (let i = 0; i < figuras.verticalg.length; i++) {
                if (figuras.verticalg[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        if (nfigura === "verticalo"){
            for (let i = 0; i < figuras.verticalo.length; i++) {
                if (figuras.verticalo[i] == casilla) {
                  return "marcador3";
                }
              }
        }
        
    }
    
    return "marcador2";
  };

  const concualfigura = () => {
    
  
    var chequeofinal = false;

    //CRUZGRANDE
    var total = figuras.cruzchica.length;
    var totalacertado = 0;
    for (let i = 0; i < figuras.cruzchica.length; i++) {
      if (figuras.cruzchica[i] == "b1") {
        totalacertado = totalacertado + salio(numeros.b1);
      }
      if (figuras.cruzchica[i] == "b2") {
        totalacertado = totalacertado + salio(numeros.b2);
      }
      if (figuras.cruzchica[i] == "b3") {
        totalacertado = totalacertado + salio(numeros.b3);
      }
      if (figuras.cruzchica[i] == "b4") {
        totalacertado = totalacertado + salio(numeros.b4);
      }
      if (figuras.cruzchica[i] == "b5") {
        totalacertado = totalacertado + salio(numeros.b5);
      }
      if (figuras.cruzchica[i] == "i1") {
        totalacertado = totalacertado + salio(numeros.i1);
      }
      if (figuras.cruzchica[i] == "i2") {
        totalacertado = totalacertado + salio(numeros.i2);
      }
      if (figuras.cruzchica[i] == "i3") {
        totalacertado = totalacertado + salio(numeros.i3);
      }
      if (figuras.cruzchica[i] == "i4") {
        totalacertado = totalacertado + salio(numeros.i4);
      }
      if (figuras.cruzchica[i] == "i5") {
        totalacertado = totalacertado + salio(numeros.i5);
      }
      if (figuras.cruzchica[i] == "n1") {
        totalacertado = totalacertado + salio(numeros.n1);
      }
      if (figuras.cruzchica[i] == "n2") {
        totalacertado = totalacertado + salio(numeros.n2);
      }
      if (figuras.cruzchica[i] == "n4") {
        totalacertado = totalacertado + salio(numeros.n4);
      }
      if (figuras.cruzchica[i] == "n5") {
        totalacertado = totalacertado + salio(numeros.n5);
      }
      if (figuras.cruzchica[i] == "g1") {
        totalacertado = totalacertado + salio(numeros.g1);
      }
      if (figuras.cruzchica[i] == "g2") {
        totalacertado = totalacertado + salio(numeros.g2);
      }
      if (figuras.cruzchica[i] == "g3") {
        totalacertado = totalacertado + salio(numeros.g3);
      }
      if (figuras.cruzchica[i] == "g4") {
        totalacertado = totalacertado + salio(numeros.g4);
      }
      if (figuras.cruzchica[i] == "g5") {
        totalacertado = totalacertado + salio(numeros.g5);
      }
      if (figuras.cruzchica[i] == "o1") {
        totalacertado = totalacertado + salio(numeros.o1);
      }
      if (figuras.cruzchica[i] == "o2") {
        totalacertado = totalacertado + salio(numeros.o2);
      }
      if (figuras.cruzchica[i] == "o3") {
        totalacertado = totalacertado + salio(numeros.o3);
      }
      if (figuras.cruzchica[i] == "o4") {
        totalacertado = totalacertado + salio(numeros.o4);
      }
      if (figuras.cruzchica[i] == "o5") {
        totalacertado = totalacertado + salio(numeros.o5);
      }
    }
    if (totalacertado == total) {
        return "cruzchica";
    }

    //CRUZGRANDE
total = figuras.cruzgrande.length;
totalacertado = 0;
for (let i = 0; i < figuras.cruzgrande.length; i++) {
  if (figuras.cruzgrande[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.cruzgrande[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.cruzgrande[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.cruzgrande[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.cruzgrande[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.cruzgrande[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.cruzgrande[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.cruzgrande[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.cruzgrande[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.cruzgrande[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.cruzgrande[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.cruzgrande[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.cruzgrande[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.cruzgrande[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.cruzgrande[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.cruzgrande[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.cruzgrande[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.cruzgrande[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.cruzgrande[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.cruzgrande[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.cruzgrande[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.cruzgrande[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.cruzgrande[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.cruzgrande[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "cruzgrande";
}


//cuatroesquinas
total = figuras.cuatroesquinas.length;
totalacertado = 0;
for (let i = 0; i < figuras.cuatroesquinas.length; i++) {
  if (figuras.cuatroesquinas[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.cuatroesquinas[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.cuatroesquinas[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.cuatroesquinas[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.cuatroesquinas[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.cuatroesquinas[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.cuatroesquinas[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.cuatroesquinas[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.cuatroesquinas[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.cuatroesquinas[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.cuatroesquinas[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.cuatroesquinas[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.cuatroesquinas[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.cuatroesquinas[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.cuatroesquinas[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.cuatroesquinas[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.cuatroesquinas[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.cuatroesquinas[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.cuatroesquinas[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.cuatroesquinas[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.cuatroesquinas[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.cuatroesquinas[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.cuatroesquinas[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.cuatroesquinas[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "cuatroesquinas";
}


//diagonal1
total = figuras.diagonal1.length;
totalacertado = 0;
for (let i = 0; i < figuras.diagonal1.length; i++) {
  if (figuras.diagonal1[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.diagonal1[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.diagonal1[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.diagonal1[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.diagonal1[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.diagonal1[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.diagonal1[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.diagonal1[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.diagonal1[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.diagonal1[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.diagonal1[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.diagonal1[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.diagonal1[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.diagonal1[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.diagonal1[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.diagonal1[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.diagonal1[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.diagonal1[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.diagonal1[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.diagonal1[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.diagonal1[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.diagonal1[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.diagonal1[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.diagonal1[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "diagonal1";
}


//diagonal2
total = figuras.diagonal2.length;
totalacertado = 0;
for (let i = 0; i < figuras.diagonal2.length; i++) {
  if (figuras.diagonal2[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.diagonal2[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.diagonal2[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.diagonal2[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.diagonal2[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.diagonal2[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.diagonal2[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.diagonal2[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.diagonal2[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.diagonal2[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.diagonal2[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.diagonal2[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.diagonal2[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.diagonal2[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.diagonal2[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.diagonal2[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.diagonal2[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.diagonal2[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.diagonal2[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.diagonal2[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.diagonal2[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.diagonal2[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.diagonal2[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.diagonal2[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "diagonal2";
}


//horizontal1
total = figuras.horizontal1.length;
totalacertado = 0;
for (let i = 0; i < figuras.horizontal1.length; i++) {
  if (figuras.horizontal1[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.horizontal1[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.horizontal1[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.horizontal1[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.horizontal1[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.horizontal1[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.horizontal1[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.horizontal1[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.horizontal1[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.horizontal1[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.horizontal1[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.horizontal1[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.horizontal1[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.horizontal1[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.horizontal1[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.horizontal1[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.horizontal1[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.horizontal1[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.horizontal1[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.horizontal1[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.horizontal1[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.horizontal1[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.horizontal1[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.horizontal1[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "horizontal1";
}

//horizontal2
total = figuras.horizontal2.length;
totalacertado = 0;
for (let i = 0; i < figuras.horizontal2.length; i++) {
  if (figuras.horizontal2[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.horizontal2[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.horizontal2[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.horizontal2[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.horizontal2[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.horizontal2[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.horizontal2[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.horizontal2[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.horizontal2[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.horizontal2[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.horizontal2[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.horizontal2[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.horizontal2[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.horizontal2[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.horizontal2[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.horizontal2[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.horizontal2[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.horizontal2[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.horizontal2[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.horizontal2[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.horizontal2[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.horizontal2[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.horizontal2[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.horizontal2[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "horizontal2";
}


//horizontal3
total = figuras.horizontal3.length;
totalacertado = 0;
for (let i = 0; i < figuras.horizontal3.length; i++) {
  if (figuras.horizontal3[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.horizontal3[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.horizontal3[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.horizontal3[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.horizontal3[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.horizontal3[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.horizontal3[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.horizontal3[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.horizontal3[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.horizontal3[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.horizontal3[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.horizontal3[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.horizontal3[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.horizontal3[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.horizontal3[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.horizontal3[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.horizontal3[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.horizontal3[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.horizontal3[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.horizontal3[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.horizontal3[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.horizontal3[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.horizontal3[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.horizontal3[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "horizontal3";
}

//horizontal4
total = figuras.horizontal4.length;
totalacertado = 0;
for (let i = 0; i < figuras.horizontal4.length; i++) {
  if (figuras.horizontal4[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.horizontal4[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.horizontal4[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.horizontal4[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.horizontal4[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.horizontal4[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.horizontal4[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.horizontal4[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.horizontal4[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.horizontal4[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.horizontal4[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.horizontal4[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.horizontal4[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.horizontal4[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.horizontal4[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.horizontal4[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.horizontal4[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.horizontal4[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.horizontal4[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.horizontal4[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.horizontal4[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.horizontal4[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.horizontal4[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.horizontal4[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "horizontal4";
}


//horizontal5
total = figuras.horizontal5.length;
totalacertado = 0;
for (let i = 0; i < figuras.horizontal5.length; i++) {
  if (figuras.horizontal5[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.horizontal5[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.horizontal5[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.horizontal5[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.horizontal5[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.horizontal5[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.horizontal5[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.horizontal5[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.horizontal5[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.horizontal5[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.horizontal5[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.horizontal5[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.horizontal5[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.horizontal5[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.horizontal5[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.horizontal5[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.horizontal5[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.horizontal5[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.horizontal5[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.horizontal5[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.horizontal5[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.horizontal5[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.horizontal5[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.horizontal5[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "horizontal5";
}

//verticalb
total = figuras.verticalb.length;
totalacertado = 0;
for (let i = 0; i < figuras.verticalb.length; i++) {
  if (figuras.verticalb[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.verticalb[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.verticalb[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.verticalb[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.verticalb[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.verticalb[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.verticalb[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.verticalb[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.verticalb[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.verticalb[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.verticalb[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.verticalb[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.verticalb[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.verticalb[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.verticalb[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.verticalb[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.verticalb[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.verticalb[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.verticalb[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.verticalb[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.verticalb[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.verticalb[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.verticalb[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.verticalb[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "verticalb";
}

//verticali
total = figuras.verticali.length;
totalacertado = 0;
for (let i = 0; i < figuras.verticali.length; i++) {
  if (figuras.verticali[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.verticali[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.verticali[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.verticali[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.verticali[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.verticali[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.verticali[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.verticali[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.verticali[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.verticali[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.verticali[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.verticali[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.verticali[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.verticali[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.verticali[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.verticali[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.verticali[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.verticali[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.verticali[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.verticali[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.verticali[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.verticali[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.verticali[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.verticali[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "verticali";
}


//verticaln
total = figuras.verticaln.length;
totalacertado = 0;
for (let i = 0; i < figuras.verticaln.length; i++) {
  if (figuras.verticaln[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.verticaln[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.verticaln[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.verticaln[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.verticaln[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.verticaln[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.verticaln[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.verticaln[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.verticaln[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.verticaln[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.verticaln[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.verticaln[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.verticaln[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.verticaln[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.verticaln[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.verticaln[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.verticaln[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.verticaln[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.verticaln[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.verticaln[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.verticaln[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.verticaln[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.verticaln[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.verticaln[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "verticaln";
}

//verticalg
total = figuras.verticalg.length;
totalacertado = 0;
for (let i = 0; i < figuras.verticalg.length; i++) {
  if (figuras.verticalg[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.verticalg[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.verticalg[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.verticalg[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.verticalg[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.verticalg[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.verticalg[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.verticalg[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.verticalg[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.verticalg[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.verticalg[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.verticalg[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.verticalg[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.verticalg[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.verticalg[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.verticalg[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.verticalg[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.verticalg[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.verticalg[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.verticalg[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.verticalg[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.verticalg[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.verticalg[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.verticalg[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "vertialg";
}


//verticalo
total = figuras.verticalo.length;
totalacertado = 0;
for (let i = 0; i < figuras.verticalo.length; i++) {
  if (figuras.verticalo[i] == "b1") {
    totalacertado = totalacertado + salio(numeros.b1);
  }
  if (figuras.verticalo[i] == "b2") {
    totalacertado = totalacertado + salio(numeros.b2);
  }
  if (figuras.verticalo[i] == "b3") {
    totalacertado = totalacertado + salio(numeros.b3);
  }
  if (figuras.verticalo[i] == "b4") {
    totalacertado = totalacertado + salio(numeros.b4);
  }
  if (figuras.verticalo[i] == "b5") {
    totalacertado = totalacertado + salio(numeros.b5);
  }
  if (figuras.verticalo[i] == "i1") {
    totalacertado = totalacertado + salio(numeros.i1);
  }
  if (figuras.verticalo[i] == "i2") {
    totalacertado = totalacertado + salio(numeros.i2);
  }
  if (figuras.verticalo[i] == "i3") {
    totalacertado = totalacertado + salio(numeros.i3);
  }
  if (figuras.verticalo[i] == "i4") {
    totalacertado = totalacertado + salio(numeros.i4);
  }
  if (figuras.verticalo[i] == "i5") {
    totalacertado = totalacertado + salio(numeros.i5);
  }
  if (figuras.verticalo[i] == "n1") {
    totalacertado = totalacertado + salio(numeros.n1);
  }
  if (figuras.verticalo[i] == "n2") {
    totalacertado = totalacertado + salio(numeros.n2);
  }
  if (figuras.verticalo[i] == "n4") {
    totalacertado = totalacertado + salio(numeros.n4);
  }
  if (figuras.verticalo[i] == "n5") {
    totalacertado = totalacertado + salio(numeros.n5);
  }
  if (figuras.verticalo[i] == "g1") {
    totalacertado = totalacertado + salio(numeros.g1);
  }
  if (figuras.verticalo[i] == "g2") {
    totalacertado = totalacertado + salio(numeros.g2);
  }
  if (figuras.verticalo[i] == "g3") {
    totalacertado = totalacertado + salio(numeros.g3);
  }
  if (figuras.verticalo[i] == "g4") {
    totalacertado = totalacertado + salio(numeros.g4);
  }
  if (figuras.verticalo[i] == "g5") {
    totalacertado = totalacertado + salio(numeros.g5);
  }
  if (figuras.verticalo[i] == "o1") {
    totalacertado = totalacertado + salio(numeros.o1);
  }
  if (figuras.verticalo[i] == "o2") {
    totalacertado = totalacertado + salio(numeros.o2);
  }
  if (figuras.verticalo[i] == "o3") {
    totalacertado = totalacertado + salio(numeros.o3);
  }
  if (figuras.verticalo[i] == "o4") {
    totalacertado = totalacertado + salio(numeros.o4);
  }
  if (figuras.verticalo[i] == "o5") {
    totalacertado = totalacertado + salio(numeros.o5);
  }
}
if (totalacertado == total) {
  return "verticalo";
}


  };

  const chequearresultados = () => {
    var total = figuras.length;
    var totalacertado = 0;
    for (let i = 0; i < figuras.length; i++) {
      if (figuras[i] == "b1") {
        totalacertado = totalacertado + salio(numeros.b1);
      }
      if (figuras[i] == "b2") {
        totalacertado = totalacertado + salio(numeros.b2);
      }
      if (figuras[i] == "b3") {
        totalacertado = totalacertado + salio(numeros.b3);
      }
      if (figuras[i] == "b4") {
        totalacertado = totalacertado + salio(numeros.b4);
      }
      if (figuras[i] == "b5") {
        totalacertado = totalacertado + salio(numeros.b5);
      }
      if (figuras[i] == "i1") {
        totalacertado = totalacertado + salio(numeros.i1);
      }
      if (figuras[i] == "i2") {
        totalacertado = totalacertado + salio(numeros.i2);
      }
      if (figuras[i] == "i3") {
        totalacertado = totalacertado + salio(numeros.i3);
      }
      if (figuras[i] == "i4") {
        totalacertado = totalacertado + salio(numeros.i4);
      }
      if (figuras[i] == "i5") {
        totalacertado = totalacertado + salio(numeros.i5);
      }
      if (figuras[i] == "n1") {
        totalacertado = totalacertado + salio(numeros.n1);
      }
      if (figuras[i] == "n2") {
        totalacertado = totalacertado + salio(numeros.n2);
      }
      if (figuras[i] == "n4") {
        totalacertado = totalacertado + salio(numeros.n4);
      }
      if (figuras[i] == "n5") {
        totalacertado = totalacertado + salio(numeros.n5);
      }
      if (figuras[i] == "g1") {
        totalacertado = totalacertado + salio(numeros.g1);
      }
      if (figuras[i] == "g2") {
        totalacertado = totalacertado + salio(numeros.g2);
      }
      if (figuras[i] == "g3") {
        totalacertado = totalacertado + salio(numeros.g3);
      }
      if (figuras[i] == "g4") {
        totalacertado = totalacertado + salio(numeros.g4);
      }
      if (figuras[i] == "g5") {
        totalacertado = totalacertado + salio(numeros.g5);
      }
      if (figuras[i] == "o1") {
        totalacertado = totalacertado + salio(numeros.o1);
      }
      if (figuras[i] == "o2") {
        totalacertado = totalacertado + salio(numeros.o2);
      }
      if (figuras[i] == "o3") {
        totalacertado = totalacertado + salio(numeros.o3);
      }
      if (figuras[i] == "o4") {
        totalacertado = totalacertado + salio(numeros.o4);
      }
      if (figuras[i] == "o5") {
        totalacertado = totalacertado + salio(numeros.o5);
      }
    }
    if (totalacertado == total) {
      document.getElementById(`boton${id}`).innerHTML = "";
      mensaje("Felicidades", "save");
    } else {
      mensaje("No tienes BINGO", "error");
    }
  };

  const salio = (numero) => {
    
    for (let i = 0; i < sacada.length; i++) {
      if (numero == sacada[i]) {
        return 1;
      }
    }

    return 0;
  };

  useEffect(() => {
    marcarresultados();
    //console.log(sacada);
  }, [sacada]);

  useEffect(() => {
    marcarblanco();
    //console.log(bolitas);
  }, [bolitas]);
  //console.log(numeros.estatus);

  return (
    <>
      <div className="flex flex-col m-4">
        <span className="h-8 flex flex-row bg-gradient-to-tr from-blue-400 to-blue-900 rounded-t-xl text-white">
          
        <span className="w-8 h-8 mt-[-6px] text-[12px] flex flex-row place-content-center bg-slate-700 items-center  rounded-full border-2 border-blue-500">{numeros.numerocarton}</span>
          <span className="ml-2 mt-1 text-white text-sm font-semibold">
            {primerNombre}
          </span>
          {idcarton.includes(id) == true ? (
            <>
              <img
                className="mt-[-20px] ml-4"
                src={trofeow}
                width="40px"
                height="60px"
              />
            </>
          ) : (
            <></>
          )}
        </span>

        <table id="bingo-table">
          <tr>
            <th>
              <span class="encabezado1">B</span>
            </th>
            <th>
              <span class="encabezado1">I</span>
            </th>
            <th>
              <span class="encabezado1">N</span>
            </th>
            <th>
              <span class="encabezado1">G</span>
            </th>
            <th>
              <span class="encabezado1">O</span>
            </th>
          </tr>
          <tr>
            <td id={`carto${id}b1`} class="">
              {numeros.b1}
            </td>
            <td id={`carto${id}i1`} class="">
              {numeros.i1}
            </td>
            <td id={`carto${id}n1`} class="">
              {numeros.n1}
            </td>
            <td id={`carto${id}g1`} class="">
              {numeros.g1}
            </td>
            <td id={`carto${id}o1`} class="">
              {numeros.o1}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b2`} class="">
              {numeros.b2}
            </td>
            <td id={`carto${id}i2`} class="">
              {numeros.i2}
            </td>
            <td id={`carto${id}n2`} class="">
              {numeros.n2}
            </td>
            <td id={`carto${id}g2`} class="">
              {numeros.g2}
            </td>
            <td id={`carto${id}o2`} class="">
              {numeros.o2}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b3`} class="">
              {numeros.b3}
            </td>
            <td id={`carto${id}i3`} class="">
              {numeros.i3}
            </td>
            <td class="marcador3 ">
              <svg
                class="w-6 h-6 text-yellow-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
              </svg>
            </td>
            <td id={`carto${id}g3`} class="">
              {numeros.g3}
            </td>
            <td id={`carto${id}o3`} class="">
              {numeros.o3}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b4`} class="">
              {numeros.b4}
            </td>
            <td id={`carto${id}i4`} class="">
              {numeros.i4}
            </td>
            <td id={`carto${id}n4`} class="">
              {numeros.n4}
            </td>
            <td id={`carto${id}g4`} class="">
              {numeros.g4}
            </td>
            <td id={`carto${id}o4`} class="">
              {numeros.o4}
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b5`} class="">
              {numeros.b5}
            </td>
            <td id={`carto${id}i5`} class="">
              {numeros.i5}
            </td>
            <td id={`carto${id}n5`} class="">
              {numeros.n5}
            </td>
            <td id={`carto${id}g5`} class="">
              {numeros.g5}
            </td>
            <td id={`carto${id}o5`} class="">
              {numeros.o5}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default Cartonfinallibre;
