import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import imgcarton from "../img/tabla.png";
import useSound from "use-sound";
import { Button, Drawer, Radio, Space } from "antd";
import { Select } from "antd";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
} from "react-firebase-hooks/firestore";
import {
  doc,
  setDoc,
  addDoc,
  getDocs,
  deleteDoc,
  getDoc,
  updateDoc,
} from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";
import Mostrarcartonf from "../componentes/mostrarcartonf";

const Asignarcarton = () => {
  const { width, height } = "100%";
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [activar, SetActivar] = useState(false);
  const [salir, setSalir] = useState(false);
  const [placement, setPlacement] = useState("bottom");

  const [favoritos, setFavoritos] = useState([]);
  const [totalcartones, setTotalcartones] = useState([2]);
  const [miscartones, setMiscartones] = useState({});
  const [cartonespendientes, setCartonespendientes] = useState([]);
  const [cartonselect, setCartonselect] = useState([]);

  //SONIDOS
  const [activar1] = useSound(popon);
  const [desactivar] = useSound(popoff);
  const [megusta] = useSound(like);
  const [comenta] = useSound(bite);
  const [principal] = useSound(menuopen);

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo) => {
    msjsave(value, tipo);
  };

  // GENERAR CARTONES
  useEffect(() => {
    //console.log(miscartones);
  }, [miscartones]);
  //

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const query = collection(db, `cartones/${email}/miscartones`);
  const [docs, loading, error] = useCollectionData(query);
  const [emailtext, setEmailtext] = useState("");
  const [nametext, setNametext] = useState("");
  const [numeros, setNumeros] = useState({});
  const [totales, setTotales] = useState({});
  const [totalasignado, setTotalasignado] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState([]);
  const [carton, setCarton] = useState("C1");
  const [numcarton, setNumcarton] = useState();
  const [actualizarcarton, setActualizarcarton] = useState(false);
  const [listadeapartados, setListadeapartados] = useState([]);

  const [users, setUsers] = useState([]);

  const getusers = async () => {
    const protemploCollection = collection(db, "cartones");
    const data = await getDocs(protemploCollection);
    //console.log(data.docs);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const datos = async () => {
    const protemploCollection = collection(db, "precio");
    const data = await getDocs(protemploCollection);
    //console.log(data.docs);
    setTotales(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const chequearexistencia = () => {
    var existe = false;
    for (let i = 0; i < users.length; i++) {
      if (users[0].email == email) {
        existe = true;
        //console.log("SI EXISTE");
      }
    }

    if (existe == false) {
      registraruser();
    }
  };

  const registraruser = async () => {
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
      };
      const protemploCollection = collection(db, "cartones");
      const userId = email;
      await setDoc(doc(protemploCollection, userId), datos);
    }
  };

  const registrarusuario = async () => {
    console.log(emailtext);
    console.log(nametext);
    const datos = {
      email: emailtext.toLowerCase(),
      nombre: nametext,
      photo: "https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Favatar.png?alt=media&token=5c3749af-725c-44f0-b797-3a9d6404349b",
    };
    const protemploCollection = collection(db, "cartones");
    const userId = emailtext.toLowerCase();
    await setDoc(doc(protemploCollection, userId), datos);
    msjsave("Registro Exitoso", "success");
  };

  const reiniciarcartones = async () => {
    const datos = {
      estatus: "pendiente",
    };
    const protemploCollection = collection(db, "fijos");
    for (let i = 1; i <= 200; i++) {
      const userId = "C" + i;

      const docRef = doc(protemploCollection, userId);
      await updateDoc(docRef, datos);
    }
    const datos2 = {
      asignados: [],
    };
    const protemploCollection2 = collection(db, "precio");
    const docRef = doc(protemploCollection2, "totales");
    await updateDoc(docRef, datos2);
  };

  const buscardocumento = async (id) => {
    const docRef = doc(db, "fijos", id);
    setCarton(id);
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          setNumeros(doc.data());
          console.log("Document data:", doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.error("Error retrieving document:", error);
      });
  };

  const MySelect = () => {
    const options = Array.from({ length: 200 }, (_, i) => `C${i + 1}`);

    return (
      <select
        id="countries"
        onChange={(e) => buscardocumento(e.target.value)}
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  };

  useEffect(() => {
    getusers();
    datos();
    chequearexistencia();
  }, [email]);

  useEffect(() => {
    console.log(numeros);
  }, [numeros]);

  useEffect(() => {
    setTotalasignado(totales);
    console.log(totales);
    if (totales.length > 0) {
      setListadeapartados(totales[0].apartados);
    }
  }, [totales]);

  useEffect(() => {
    console.log(listadeapartados);
  }, [listadeapartados]);

  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-10 h-10 cursor-pointer  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>

      <br />

      {email == "enmarycarrasco@gmail.com" ? (
        <>
          <div class=" flex">
            <div className=" bg-white w-full">
              <div className="m-4 text-blue-700 font-bold">
                Ingrese el Email:
              </div>
              <div className="m-4">
                <input
                  type="email"
                  id="emailtext"
                  onChange={(e) => setEmailtext(e.target.value.toLowerCase())}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="bingo@gmail.com"
                  required
                />
              </div>
              <div className="m-4 text-blue-700 font-bold">
                Nombre y Apellido
              </div>
              <div className="m-4">
                <input
                  type="text"
                  id="nametext"
                  onChange={(e) => setNametext(e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Bingo"
                  required
                />
              </div>
              <div className="m-4">
                <button
                  type="button"
                  onClick={() => {
                    registrarusuario();
                  }}
                  class=" mt-2 w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
                >
                  REGISTRAR USUARIO
                </button>
              </div>
            </div>
          </div>

          <div class=" flex">
            <div className=" bg-white w-full">
              <div className="m-4 text-blue-700 font-bold">
                Seleccione Su carton:
              </div>
              <div className="m-4">{MySelect()}</div>
              <div className="m-4">
                {totales == undefined ? (
                  <div></div>
                ) : (
                  <div>
                    <Mostrarcartonf
                      numeros={numeros}
                      email={emailtext}
                      carton={carton}
                      asignados={totales}
                      listaapartados={listadeapartados}
                    />
                  </div>
                )}
              </div>
              <div className="m-4 text-blue-700 font-bold">Estatus</div>
              <div className="m-4">PENDIENTE</div>
              <div className="m-4">
                <button
                  type="button"
                  onClick={() => {
                    reiniciarcartones();
                  }}
                  class=" mt-2 w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
                >
                  REINICIAR CARTONES PREDEFINIDOS
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
      <div>{open == true || open2 == true ? <></> : <></>}</div>
      <ToastContainer theme="dark" />
      {salir ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Salir</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Deseas Cerrar Sesi&oacute;n?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLogout();
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Asignarcarton;
