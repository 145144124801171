import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";

import { Link, useParams } from "react-router-dom";



const Register = () => {
  const { width, height } = "100%";

  const [salir, setSalir] = useState(false);


  const { referido } = useParams();
  const [emailreferido, setEmailreferido] = useState("")
  const [nombrereferido, setNombrereferido] = useState("")
  const [photoreferido, setPhotoreferido] = useState("https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Favatar.png?alt=media&token=5c3749af-725c-44f0-b797-3a9d6404349b")






  const obtenerinvitador = (referido) => {

    const datos = {
       referido: referido,
    }
       //enviar datos
       fetch(`http://localhost:3001/obtenercorreo`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result) {
        setEmailreferido(result.email)
        setNombrereferido(result.nombre)
        setPhotoreferido(result.photo)
        
        } else {
        console.log('ERROR DE CONEXION');
        }
        })
        .catch((error) => {
        console.log(error);
        });
        //fin de envio
     

  }

  useEffect(() => {
    obtenerinvitador(referido);
  }, [referido]);

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

 

  const registraruser = async () => {
    
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
        referido: emailreferido,
        usuario: ""
      };

      registrarusuario(datos)
    }
  };


  const registrarusuario = async (datos) => {

   
    //enviar datos
    fetch(`http://localhost:3001/nuevouser`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos),
    })
    .then((response) => response.json())
    .then((result) => {
    if (result) {
    console.log(result);
    } else {
    console.log('ERROR DE CONEXION');
    }
    })
    .catch((error) => {
    console.log(error);
    });
    //fin de envio
 

  }

  const chequearexistencia = async () => {

    const datos = {
        email: email,
    }

     //enviar datos
     fetch(`http://localhost:3001/chequearexistencia`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result) {
            if(result.message == "Si existe"){
                obtenerinvitador(result.referido)
            }
            else{
                console.log("no existe")
               registraruser()
            }
        } else {
        console.log('ERROR DE CONEXION');
        }
        })
        .catch((error) => {
        console.log(error);
        });
        //fin de envio

  }

  useEffect(() => {


  if (nombrereferido == "" || nombrereferido == undefined) {
  } else {
    let nombre = nombrereferido;
    let nombreprimero = "";

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        nombreprimero += nombre[i];
      }
    }

    setNombrereferido(nombreprimero)

  }

  }, [nombrereferido]);

  useEffect(() => {
    chequearexistencia();
  }, [email]);


  return (
    <>
      <div className="container2 w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          
        </div>
      </div>

      
      <div>
      
      <div className="p-4">
<div className=" rounded-md bg-slate-400 ">
    <div className="bg-blue-900 rounded-t-md text-yellow-400 text-md font-semibold p-2 flex flex-row place-content-center"><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
</svg>
&nbsp;Registro por Invitación</div>

   
    {displayName == undefined ? (
        <>
            <div className="pt-8 flex  place-content-center">
            <button onClick={(e) => handleGoogle(e)} type="button" class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2">
            <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
            <path fill-rule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clip-rule="evenodd"/>
            </svg>
            Registro con GOOGLE (Gmail)
            </button></div>
            <div className="text-center mt-6 bg-slate-900 text-green-400 font-bold w-full rounded-lg p-2">Tu Invitador es</div>
            <div className="flex flex-row place-content-center items-center gap-4 mt-4">
              <img
               
                className="w-10 h-10  rounded-full border-2 border-blue-500"
                src={`${photoreferido}`}
                alt=""
              />

              <div className="font-medium text-white">
                <div className="text-blue-600 text-center font-bold text-sm">
                  {nombrereferido}
                </div>
              </div>
            </div></>
          ) : (
            <div className="pt-8 flex place-content-center">
            <div className="flex flex-col">
              <div className="p-2">
                <div className="flex flex-col items-center gap-4 ">
                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-20 h-20  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />

                  <div className="font-medium text-white flex place-content-center mt-[-10px]">
                    <div className="text-white text-center text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex place-content-center flex-col">
                <span className="text-center text-red-600 text-xl font-bold">BIENVENIDO</span>
                <span className="text-center text-blue-950 font-semibold text-md">Ya Eres Miembro de BingoArcoiris.com</span>
                <Link to="/"> <div className="w-full flex flex-row place-content-center"><button type="button" class="text-white flex flex-row bg-gradient-to-r from-blue-500 w-[50%] via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z" clip-rule="evenodd"/>
</svg>
<span className="ml-2 mt-[2px]">A Jugar</span></button></div></Link>
                <div className="text-center mt-6 bg-slate-900 text-green-400 font-bold w-full rounded-lg p-2">Tu Invitador es</div>
                <div className="flex flex-row place-content-center items-center gap-4 mt-4">
                  <img
                   
                    className="w-10 h-10  rounded-full border-2 border-blue-500"
                    src={`${photoreferido}`}
                    alt=""
                  />

                  <div className="font-medium text-white">
                    <div className="text-blue-600 text-center font-bold text-sm">
                      {nombrereferido}
                    </div>
                  </div>
                </div>

                </div> 
             
            </div>
            
            </div>
          )}
   
   <br />
   <div className="h-8 bg-blue-900 rounded-b-lg"></div>

     </div>
      </div>
        {/* <Drawer
          title="Mis Cartones"
          placement={placement}
          closable={false}
          onClose={onClose}
          open={open}
          key={placement}
        >
          <div class="flex items-start gap-2.5">
            {documents.map((doc, index) => (
              <>
                {doc.data.estatus == "pendiente" ? (
                  <div key={doc.id}>
                    <button
                      type="button"
                      onClick={() => {
                        setDocument(doc.id);
                        setCarton(doc.data);
                        setNumcarton(index + 1);
                      }}
                      class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        class="w-6 h-6 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 8v-2h7v2H4Zm0 2v2h7v-2H4Zm9 2h7v-2h-7v2Zm7-4v-2h-7v2h7Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      &nbsp;
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
            {displayName == undefined ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => {
                  comprar();
                }}
                class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                  />
                </svg>
                &nbsp;Comprar
              </button>
            )}
          </div>
          <br />
          <div className="flex">
            {carton == "" ? (
              <div></div>
            ) : (
              <>
                <Mostrarcarton email={email} numeros={carton} />
                <div className="flex flex-col">
                  <div className="p-4 text-blue-900 font-bold text-xl">
                    Carton #{numcarton}
                  </div>
                  <div className="pl-4">
                    <button
                      type="button"
                      onClick={() => {
                        eliminarcarton(document);
                      }}
                      class="text-white  bg-[#ff1919f0] hover:bg-[#ff1919f0]/80 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2 mb-2"
                    >
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Eliminar
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Drawer>

        <Drawer
          title="Mis Cartones Disponibles para Jugar"
          placement={placement}
          closable={false}
          onClose={onClose2}
          open={open2}
          key={placement}
        >
          <div class="flex items-start gap-2.5">
            {documents.map((doc, index) => (
              <>
                {doc.data.estatus !== "pendiente" ? (
                  <div key={doc.id}>
                    <button
                      type="button"
                      onClick={() => {
                        setDocument(doc.id);
                        setCarton(doc.data);
                        setNumcarton(index + 1);
                      }}
                      class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        class="w-6 h-6 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 8v-2h7v2H4Zm0 2v2h7v-2H4Zm9 2h7v-2h-7v2Zm7-4v-2h-7v2h7Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      &nbsp;#{index + 1}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
          <br />
          <div className="flex">
            {carton == "" ? (
              <div></div>
            ) : (
              <>
                <Mostrarcarton email={email} numeros={carton} />
                <div className="flex flex-col">
                  <div className="p-4 text-blue-900 font-bold text-xl">
                    Carton #{numcarton}
                  </div>
                </div>
              </>
            )}
          </div>
        </Drawer> */}

        {/* {open == true || open2 == true ? (
          <div></div>
        ) : (
          <div className="footer bg-black flex flex-wrap items-center p-0 justify-between text-white">
            <div className=" flex flex-wrap">
              <Link to="/cartones">
                <div className="">
                  <button
                    onClick={() => {
                      principal();
                    }}
                    type="button"
                    class=" w-full  text-blue-700  hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 "
                  >
                    <svg
                      class="w-[48px] h-[48px] text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.857 3A1.857 1.857 0 0 0 3 4.857v4.286C3 10.169 3.831 11 4.857 11h4.286A1.857 1.857 0 0 0 11 9.143V4.857A1.857 1.857 0 0 0 9.143 3H4.857Zm10 0A1.857 1.857 0 0 0 13 4.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 21 9.143V4.857A1.857 1.857 0 0 0 19.143 3h-4.286Zm-10 10A1.857 1.857 0 0 0 3 14.857v4.286C3 20.169 3.831 21 4.857 21h4.286A1.857 1.857 0 0 0 11 19.143v-4.286A1.857 1.857 0 0 0 9.143 13H4.857ZM18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class=" absolute inline-flex items-center justify-center w-32 h-6 ml-12 mt-[-2px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-lg">
                      OBTEN TU CARTON
                    </span>
                  </button>
                </div>
              </Link>
            </div>

            <div className=" flex flex-wrap">
              <div className="">
                <button
                  onClick={() => {
                    showDrawer2();
                    setCarton([]);
                    principal();
                  }}
                  type="button"
                  class=" w-full  text-blue-700  hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 "
                >
                  <svg
                    class=" relative w-[48px] h-[48px] text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class=" absolute inline-flex items-center justify-center w-6 h-6 ml-8 mt-[-26px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                    {totalc}
                  </span>
                </button>
              </div>
              <div className="">
                <button
                  type="button"
                  onClick={() => {
                    showDrawer();
                    setCarton([]);
                    comenta();
                  }}
                  class="relative  w-full  font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2"
                >
                  <svg
                    class="w-[48px] h-[48px] text-white dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class=" absolute inline-flex items-center justify-center w-6 h-6 ml-8 mt-[-26px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                    {totalp}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )} */}
      </div>
      
      {salir ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Salir</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Deseas Cerrar Sesi&oacute;n?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLogout();
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Register;