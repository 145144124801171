import React, { useState, useEffect } from 'react';

const Temporizador = ({ tiempo1 }) => {
  const [tiempoRestante1, setTiempoRestante1] = useState(0);

  useEffect(() => {
    // Función para calcular el tiempo restante
    const calcularTiempoRestante = () => {
      const ahora = new Date();
      const fin1 = new Date(tiempo1);

      
 
        if(ahora > fin1){
            setTiempoRestante1(0);
        }else{
            setTiempoRestante1(fin1 - ahora);
        }
      
   
    };

    // Calcular el tiempo restante inicialmente
    calcularTiempoRestante();

    // Configurar un intervalo para actualizar el tiempo restante cada segundo
    const intervalo = setInterval(() => {
      calcularTiempoRestante();
    }, 1000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalo);
  }, [tiempo1]);

  // Función para formatear el tiempo restante en horas, minutos y segundos
  const formatearTiempo = (tiempo) => {
    const horas = Math.floor((tiempo % (1000 * 3600 * 24)) / (1000 * 3600));
    const minutos = Math.floor((tiempo % (1000 * 3600)) / (1000 * 60));
    const segundos = Math.floor((tiempo % (1000 * 60)) / 1000);
    if(horas == 0 && minutos == 0){
        return `${segundos} segundos`;
    }
    if(horas == 0){
        return `${minutos}min ${segundos} seg`;  
    }
    return `${horas}:${minutos}:${segundos}`;

   
  };

  return (
    <>
      {formatearTiempo(tiempoRestante1)}
    </>
  );
};

export default Temporizador;