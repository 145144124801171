import React from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, updateDoc } from "@firebase/firestore";

const Mostrarcartonf = ({
  numeros,
  email,
  carton,
  asignados,
  listaapartados,
}) => {
  const agregarausuario = async () => {
    const path = `cartones/${email}/miscartones`;
    const cartonesCollection = collection(db, path);

    addDoc(cartonesCollection, {
      estatus: "comprado",
      numerocarton: numeros.numerocarton,
      b1: numeros.b1,
      b2: numeros.b2,
      b3: numeros.b3,
      b4: numeros.b4,
      b5: numeros.b5,
      i1: numeros.i1,
      i2: numeros.i2,
      i3: numeros.i3,
      i4: numeros.i4,
      i5: numeros.i5,
      n1: numeros.n1,
      n2: numeros.n2,
      n4: numeros.n4,
      n5: numeros.n5,
      g1: numeros.g1,
      g2: numeros.g2,
      g3: numeros.g3,
      g4: numeros.g4,
      g5: numeros.g5,
      o1: numeros.o1,
      o2: numeros.o2,
      o3: numeros.o3,
      o4: numeros.o4,
      o5: numeros.o5,
    });

    var listaa = listaapartados;

    console.log(carton);
    console.log(carton.slice(1));
    listaa.push(parseInt(carton.slice(1)));
    //asignados.push(carton);
    var asignadost = asignados[0].asignados;
    asignadost.push(carton);

    console.log(asignadost);
    const datos3 = {
      asignados: asignadost,
    };

    const protemploCollection2 = collection(db, "precio");

    const docRef2 = doc(protemploCollection2, "totales");
    await updateDoc(docRef2, datos3);

    const datos2 = {
      estatus: "comprado",
    };

    const protemploCollection = collection(db, "fijos");
    const userId = carton;
    const docRef = doc(protemploCollection, userId);
    await updateDoc(docRef, datos2);

    const datos4 = {
      apartados: listaa,
    };

    const protemploCollection3 = collection(db, "precio");
    const userId3 = "totales";
    const docRef3 = doc(protemploCollection3, userId3);
    await updateDoc(docRef3, datos4);

    alert("Carton Asignado como comprado");
  };
  const agregarregaloausuario = async () => {
    const path = `cartones/${email}/miscartones`;
    const cartonesCollection = collection(db, path);

    addDoc(cartonesCollection, {
      estatus: "regalo",
      numerocarton: numeros.numerocarton,
      b1: numeros.b1,
      b2: numeros.b2,
      b3: numeros.b3,
      b4: numeros.b4,
      b5: numeros.b5,
      i1: numeros.i1,
      i2: numeros.i2,
      i3: numeros.i3,
      i4: numeros.i4,
      i5: numeros.i5,
      n1: numeros.n1,
      n2: numeros.n2,
      n4: numeros.n4,
      n5: numeros.n5,
      g1: numeros.g1,
      g2: numeros.g2,
      g3: numeros.g3,
      g4: numeros.g4,
      g5: numeros.g5,
      o1: numeros.o1,
      o2: numeros.o2,
      o3: numeros.o3,
      o4: numeros.o4,
      o5: numeros.o5,
    });

    //asignados.push(carton);
    var asignadost = asignados[0].asignados;
    asignadost.push(carton);

    console.log(asignadost);
    const datos3 = {
      asignados: asignadost,
    };

    const protemploCollection2 = collection(db, "precio");

    const docRef2 = doc(protemploCollection2, "totales");
    await updateDoc(docRef2, datos3);

    const datos2 = {
      estatus: "comprado",
    };

    const protemploCollection = collection(db, "fijos");
    const userId = carton;
    const docRef = doc(protemploCollection, userId);
    await updateDoc(docRef, datos2);

    alert("Carton Asignado como regalo");
  };
  return (
    <>
      <table id="bingo-table">
        <tr>
          <th>
            <span class="encabezado1">B</span>
          </th>
          <th>
            <span class="encabezado1">I</span>
          </th>
          <th>
            <span class="encabezado1">N</span>
          </th>
          <th>
            <span class="encabezado1">G</span>
          </th>
          <th>
            <span class="encabezado1">O</span>
          </th>
        </tr>
        <tr>
          <td class="">{numeros.b1}</td>
          <td class="">{numeros.i1}</td>
          <td class="">{numeros.n1}</td>
          <td class="">{numeros.g1}</td>
          <td class="">{numeros.o1}</td>
        </tr>
        <tr>
          <td class="">{numeros.b2}</td>
          <td class="">{numeros.i2}</td>
          <td class="">{numeros.n2}</td>
          <td class="">{numeros.g2}</td>
          <td class="">{numeros.o2}</td>
        </tr>
        <tr>
          <td class="">{numeros.b3}</td>
          <td class="">{numeros.i3}</td>
          <td class="marcador3 ">
            <svg
              class="w-6 h-6 text-yellow-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
            </svg>
          </td>
          <td class="">{numeros.g3}</td>
          <td class="">{numeros.o3}</td>
        </tr>
        <tr>
          <td class="">{numeros.b4}</td>
          <td class="">{numeros.i4}</td>
          <td class="">{numeros.n4}</td>
          <td class="">{numeros.g4}</td>
          <td class="">{numeros.o4}</td>
        </tr>
        <tr>
          <td class="">{numeros.b5}</td>
          <td class="">{numeros.i5}</td>
          <td class="">{numeros.n5}</td>
          <td class="">{numeros.g5}</td>
          <td class="">{numeros.o5}</td>
        </tr>
      </table>
      {numeros.estatus == "pendiente" ? (
        <>
          <button
            type="button"
            onClick={() => {
              agregarausuario();
            }}
            class=" flex place-content-center mt-2 w-[50%] text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
          >
            <svg
              class="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 3a1 1 0 0 0 0 2h.687L7.82 15.24A3 3 0 1 0 11.83 17h2.34A3 3 0 1 0 17 15H9.813l-.208-1h8.145a1 1 0 0 0 .979-.796l1.25-6A1 1 0 0 0 19 6h-2.268A2 2 0 0 1 15 9a2 2 0 1 1-4 0 2 2 0 0 1-1.732-3h-1.33L7.48 3.796A1 1 0 0 0 6.5 3H5Z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M14 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V8h1a1 1 0 1 0 0-2h-1V5Z"
                clip-rule="evenodd"
              />
            </svg>
            <span>&nbsp;Agregar</span>
          </button>
          <button
            type="button"
            onClick={() => {
              agregarregaloausuario();
            }}
            class=" flex place-content-center mt-2 w-[50%] text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
          >
            <svg
              class="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 3a1 1 0 0 0 0 2h.687L7.82 15.24A3 3 0 1 0 11.83 17h2.34A3 3 0 1 0 17 15H9.813l-.208-1h8.145a1 1 0 0 0 .979-.796l1.25-6A1 1 0 0 0 19 6h-2.268A2 2 0 0 1 15 9a2 2 0 1 1-4 0 2 2 0 0 1-1.732-3h-1.33L7.48 3.796A1 1 0 0 0 6.5 3H5Z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M14 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V8h1a1 1 0 1 0 0-2h-1V5Z"
                clip-rule="evenodd"
              />
            </svg>
            <span>&nbsp;Regalo</span>
          </button>
        </>
      ) : (
        <button
          type="button"
          class=" flex place-content-center mt-2 w-[50%] text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
        >
          <svg
            class="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M5 3a1 1 0 0 0 0 2h.687L7.82 15.24A3 3 0 1 0 11.83 17h2.34A3 3 0 1 0 17 15H9.813l-.208-1h8.145a1 1 0 0 0 .979-.796l1.25-6A1 1 0 0 0 19 6h-2.268A2 2 0 0 1 15 9a2 2 0 1 1-4 0 2 2 0 0 1-1.732-3h-1.33L7.48 3.796A1 1 0 0 0 6.5 3H5Z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M14 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V8h1a1 1 0 1 0 0-2h-1V5Z"
              clip-rule="evenodd"
            />
          </svg>
          <span>&nbsp;ASIGNADO</span>
        </button>
      )}
    </>
  );
};

export default Mostrarcartonf;
